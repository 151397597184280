.logoContainer {
  height: 30vh;
  width: 100%;
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}


