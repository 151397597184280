@use "./variables" as vars;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@300;400;700&display=swap");

.ultra-bold-title-Sans {
  font-family: vars.$font-Sans;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xl;
  line-height: 100%;
}

.bold-title-Sans {
  font-family: vars.$font-Sans;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xl;
  line-height: 100%;
}

.title-Sans {
  font-family: vars.$font-Sans;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-l;
  line-height: 100%;
}

.subtitle-Sans {
  font-family: vars.$font-Sans;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-ml;
  line-height: 100%;
}

.text-Sans {
  font-family: vars.$font-Sans;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-s;
  line-height: 100%;
}

// .bold-title-100 {
//   font-family: vars.$font-Sans-400;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   line-height: 100%;
// }

// .bold-title {
//   font-family: vars.$font-Sans-400;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   line-height: 100%;
// }

// .bigger-title {
//   font-family: vars.$font-Sans-400;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: bold;
//   letter-spacing: normal;
//   font-size: 12rem;
//   line-height: 100%;
//   @media screen and (max-width: 2000px) {
//     font-size: 10rem !important;
//   }
//   @media screen and (max-width: 800px) {
//     font-size: 4.3rem !important;
//   }
// }
// // .bigger-title-faq {
// //   font-family: vars.$font-helvetica-bold;
// //   font-weight: 700;
// //   font-stretch: normal;
// //   font-style: bold;
// //   letter-spacing: normal;
// //   font-size: 12rem;
// //   line-height: 100%;
// //   @media screen and (max-width: 2000px) {
// //     font-size: 10rem !important;
// //   }
// //   @media screen and (max-width: 800px) {
// //     font-size: 7.5rem !important;
// //   }
// // }

// .title-mob-e {
//   font-family: vars.$font-esquare;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: 5rem;
//   line-height: 100%;
// }

// .bigger-title-mob {
//   font-family: vars.$font-helvetica-bold;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   line-height: 100%;
// }

// .bigger-title-esquare {
//   font-family: vars.$font-esquare;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: 9.5rem;
//   line-height: 100%;
// }

// .esquare-title {
//   font-family: vars.$font-esquare;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   line-height: 100%;
// }
// .esquare-title-prod {
//   font-family: vars.$font-esquare;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: 5rem;
//   line-height: 100%;
//   @media screen and (max-width: 2000px) {
//     font-size: 4rem !important;
//   }
//   @media screen and (max-width: 800px) {
//     font-size: 2.5rem !important;
//   }
// }

// .bold-title-helvetica {
//   font-family: vars.$font-helvetica-bold;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   line-height: 100%;
// }

// .light-title {
//   font-family: vars.$font-helvetica;
//   font-weight: 500;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 90%;
//   letter-spacing: normal;
//   font-size: vars.$font-size-l;
//   @media screen and (max-width: 750px) {
//     font-size: 2.1rem;
//   }
// }

// .small-title {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 90%;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xl;
//   @media screen and (max-width: 750px) {
//     font-size: 4rem;
//   }
// }
// .small-title-assistance {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 90%;
//   letter-spacing: normal;
//   font-size: 4rem;
//   @media screen and (max-width: 750px) {
//     font-size: 4rem;
//   }
// }

// .small-title-mob {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 90%;
//   letter-spacing: normal;
//   font-size: 3.5rem;
// }

// .sub-title {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-m;
//   @media screen and (max-width: 750px) {
//     font-size: 1.6rem;
//   }
// }
// .sub-title-faq {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2.2rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.5rem;
//   }
// }

// .sub-title-productdiv {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2.3rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.6rem;
//   }
//   @media (min-width: 2000px), (min-height: 1250px) {
//     font-size: 2.5rem;
//   }
// }

// .sub-title-ml {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.5rem;
//   @media screen and (max-width: 750px) {
//     font-size: 2rem;
//   }
// }
// .card-price {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2rem;
//   @media screen and (max-width: 750px) {
//     font-size: 2rem;
//   }
// }

// .sub-title-text {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.9rem;
//   @media screen and (max-width: 750px) {
//     font-size: 2rem;
//   }
// }

// .sub-title-mob {
//   font-family: vars.$font-helvetica;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.6rem;
// }

// .sub-title-brief {
//   font-family: vars.$font-body;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: bold;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-m;
// }

// .sub-title-esquare {
//   font-family: vars.$font-esquare;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2.2rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.3rem;
//   }
// }

// .sub-title-editable {
//   font-family: vars.$font-body;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2rem;
// }

// .bigger-text-bold {
//   font-family: vars.$font-body;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-l;
// }

// .bigger-text {
//   font-family: vars.$font-body;
//   font-weight: 300;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-l;
// }

// .text-xxl {
//   font-family: vars.$font-body;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 98%;
//   letter-spacing: normal;
//   font-size: vars.$font-size-m;
// }

// .text-xl {
//   font-family: vars.$font-body;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 120%;
//   letter-spacing: normal;
// }

// .text {
//   font-family: vars.$font-body;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xs;
// }

// .text-language {
//   font-family: vars.$font-helvetica;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xs;
// }
// .text-faq {
//   font-family: vars.$font-body;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.5rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.2rem;
//   }
// }

// .text-16 {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-m;
//   @media screen and (max-width: 750px) {
//     font-size: 1.5rem;
//   }
// }

// .text-16-hometitle {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2.5rem;
//   @media screen and (max-width: 750px) {
//     font-size: 2rem;
//   }
// }
// .text-16-homedescr {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 2rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.7rem;
//   }
// }

// .text-16-esquare {
//   font-family: vars.$font-esquare;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.7rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.3rem;
//   }
// }

// .text-16-tab {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: 1.7rem;
//   @media screen and (max-width: 750px) {
//     font-size: 1.3rem;
//   }
// }

// .text-s {
//   font-family: vars.$font-body;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xxs;
// }

// .sub-title-helvetica {
//   font-family: vars.$font-helvetica-bold;
//   font-weight: 700;
//   font-stretch: normal;
//   // transform: translateY(-7px);
//   font-style: bold;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-m;
// }

// .text-helvetica {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xs;
//   @media screen and (max-width: 800px) {
//     font-size: 1rem;
//   }
// }

// .text-16-helvetica {
//   font-family: vars.$font-helvetica;
//   font-weight: 400;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   font-size: vars.$font-size-xs-16;
// }
