.pageContainer {
  min-height: 100vh;

  background-color: #e9e9e9;
  .logoContainer {
    max-width: 100%;
    img {
      width: 100%;
    }
  }

  .imageContainer {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    // border: 1px solid red;
    .scritta {
      width: 50%;
    }
    .immagine {
      width: 70%;
    }
  }

  .cocktail {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    // height: 440px;

    .cocktailImg {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      // height: 220px;
      // border-radius: 10px;
      overflow: hidden;
      //   border: 2px solid red;
      img {
        // border: 1px solid blue;
        width: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        object-fit: fill;
        // height: 220px;
      }
    }
    .vaporeImg {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      // height: 220px;
      // border-radius: 10px;
      overflow: hidden;
      // border: 2px solid blue;
      img {
        object-fit: fill;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // border: 1px solid blue;
        // max-height: 220px;
        width: 100%;
      }
    }
  }

  .treatmentImg {
    height: 500px;
    width: 100%;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .bimageContainer {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    .bimage {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: end;

      img {
        height: 200px;
        max-width: 100%;
      }
    }
  }

  .takeAway {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    // padding: 1.5rem;
    // opacity: 0.7;
    // border: 0.5px solid black;
  }

  .butter {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row;
    height: 270px;

    img {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      object-fit: cover;
    }
  }
  .flan {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    height: 500px;
    // border: 1px solid red;
    img {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cocoa {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row;
    // border: 1px solid red;
    overflow: hidden;
    .cocoaImg {
      //   border-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-right: 3px;
      width: 100%;
      height: 350px;
      object-fit: cover;
    }

    .cocoaImg2 {
      //   border-radius: 10px;
      padding-left: 3px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      height: 350px;
      //   border: 1px solid red;
      object-fit: cover;
    }
  }

  .henne {
    img {
      width: 100%;
      height: 450px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      object-fit: fill;
      //   height: 100%;
    }
  }
  .amarone {
    // padding-bottom: 30px;
    .slider {
      width: 100%;

      // animation: fadeInAnimation ease 1s;
      // animation-iteration-count: 1;
      // animation-fill-mode: forwards;

      object-fit: cover;

      height: 500px;
      border-radius: 10px;
      object-fit: cover;

      .arrowLeft {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        // top: 50%;

        // border: 1px solid red;
      }
      .arrowRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        // top: 50%;

        // border: 1px solid red;
      }
      img {
        // animation: fadeInAnimation ease 1s;
        // animation-iteration-count: 1;
        // animation-fill-mode: forwards;
        z-index: 1;
        border-radius: 10px;

        height: 100%;
      }
    }
    // .left {
    //   width: 100%;
    //   height: 500px;
    //   // padding-right: 3px;
    //   // border-top-left-radius: 10px;
    //   // border-bottom-left-radius: 10px;
    //   animation: fadeInAnimation ease 1s;
    //   animation-iteration-count: 1;
    //   // animation-fill-mode: forwards;
    //   border-radius: 10px;
    //   object-fit: cover;
    //   //   height: 100%;
    // }
    // .right {
    //   width: 100%;
    //   height: 500px;
    //   // padding-left: 3px;
    //   animation: fadeInAnimation ease 1s;
    //   animation-iteration-count: 1;
    //   // animation-fill-mode: forwards;
    //   border-radius: 10px;
    //   object-fit: cover;
    //   //   height: 100%;
    // }
  }
}

.swiper {
  width: 100%;
  height: 500px;
  // border: 1px solid red;
  .slide {
    border-radius: 10px;
    img {
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
